import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { vueApp } = useNuxtApp()

  vueApp.use(VueReCaptcha, {
    siteKey: config.public.recaptcha.siteKey,
    loaderOptions: { autoHideBadge: true },
  })
})
