import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(localeData)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

export default defineNuxtPlugin(() => {
  return {
    provide: {
      dayjs,
    },
  }
})
