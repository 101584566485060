import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  if (config.public.isProduction) {
    nuxtApp.vueApp.use(VueGtag, {
      property: {
        id: config.public.googleAnalytics.id,
      },
    })
    trackRouter(useRouter)
  }
})
