<script setup>
useHead({
  htmlAttrs: { lang: 'en' },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - First Reformed Church of St. Catharines` : 'First Reformed Church of St. Catharines'
  },
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="rgb(var(--v-theme-primary))" />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
